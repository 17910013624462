<template>
  <div
    class="
      flex flex-col
      w-full
      bg-lightGrey
      rounded-xl
      text-grey
      justify-center
    "
  >
    <div class="w-3/4 flex flex-col m-auto gap-y-5 mb-3">
      <div class="flex justify-between">
        <span class="self-start text-sm font-bold mt-5">{{ title }}</span>
        <div class="flex">
          <div
            class="flex items-center"
            v-for="option in options"
            :key="option"
          >
            <input
              v-model="optionStatus"
              :id="`link-radio${option}`"
              name="additionalStaffrequired"
              type="radio"
              :value="option"
              class="w-4 h-4"
              @change="$emit('toggle-status', option)"
            />
            <label
              :for="`link-radio${option}`"
              class="mx-2 text-sm font-medium text-gray-600"
              >{{ option }}
            </label>
          </div>
        </div>
      </div>
      <template v-if="optionStatus === 'yes'">
        <v-select
          v-model="filterSelection"
          placeholder="Search By"
          label="customLabel"
          :options="filters"
          :class="
            v$.inputSearchValue.$error === true
              ? 'filterDropDown-error'
              : 'filterDropDown'
          "
        ></v-select>
        <input
          v-model="inputSearchValue"
          type="text"
          class="border rounded-md p-1"
          :class="
            v$.inputSearchValue.$error === true
              ? 'border-red'
              : 'border-darkGrey'
          "
        />
      </template>
    </div>
    <template v-if="optionStatus === 'yes'">
      <div class="flex justify-center items-center">
        <base-button
          @click="submitSearch"
          backgroundColour="bg-teal hover:bg-orange-light"
          >Search</base-button
        >
      </div>
      <template v-if="!loadingUsers">
        <div class="w-3/4 mx-auto overflow-x-auto h-16">
          <ul class="ml-5">
            <li class="">
              <div
                class="flex items-center gap-x-2"
                v-for="user in responseData"
                :key="user.id"
              >
                <input
                  :id="'additionalUser' + user.id"
                  type="radio"
                  :value="user.id"
                  v-model="selectedUser"
                />
                <label :for="'additionalUser' + user.id">{{
                  user.email
                }}</label>
              </div>
            </li>
          </ul>
        </div>
      </template>
      <base-spinner v-else />
    </template>
  </div>
</template>

<script>
import BaseButton from "@/components/ui/baseComponents/BaseButton.vue";
import BaseSpinner from "../../../../ui/baseComponents/BaseSpinner.vue";
import singleItemSearch from "@/mixins/Search/singleItemSearch.js";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
export default {
  components: { BaseButton, BaseSpinner },
  mixins: [singleItemSearch],
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      v$: useVuelidate(),
      selectedUser: null,
      optionStatus: "no",
      filters: [
        {
          customLabel: "Email",
          value: "email",
        },
        {
          customLabel: "Last Name",
          value: "last_name",
        },
        {
          customLabel: "Mobile Phone",
          value: "mobile_phone",
        },
      ],
      options: ["yes", "no"],
    };
  },
  validations() {
    return {
      filterSelection: { required },
      inputSearchValue: { required },
    };
  },
  watch: {
    selectedUser() {
      return this.$emit("set-additional-staff-member", this.selectedUser);
    },
  },
};
</script>

<style>
.filterDropDown .vs__dropdown-toggle,
.filterDropDown .vs__dropdown-menu {
  border: 1px solid #425563;
  border-radius: 0.5rem;
  background: white;
  padding-left: 0.2rem;
}
.filterDropDown .vs__dropdown-toggle {
  width: 100%;
}
#filterDropDown .filterDropDown .vs__clear,
#filterDropDown .filterDropDown .vs__open-indicator {
  fill: black;
}
.filterDropDown-error .vs__dropdown-toggle,
.filterDropDown-error .vs__dropdown-menu {
  border: 1px solid red;
  border-radius: 0.5rem;
  background: white;
  padding-left: 0.2rem;
}
.filterDropDown-error .vs__dropdown-toggle {
  width: 100%;
}
#filterDropDown-error .filterDropDown .vs__clear,
#filterDropDown-error .filterDropDown .vs__open-indicator {
  fill: black;
}
</style>
