<template>
  <div class="flex self-start w-full space-x-7 h-full overflow-hidden">
    <div
      class="rounded-xl flex flex-col gap-y-3 items-center border-lightGrey text-grey w-4/6 my-2 ml-2 overflow-scroll overflow-x-hidden"
    >
      <slot name="leftSide"></slot>
    </div>

    <div
      class="rounded-xl flex flex-col gap-y-3 items-center border-lightGrey text-grey w-2/6 my-2 ml-2 overflow-scroll overflow-x-hidden"
    >
      <slot name="rightSide"></slot>
    </div>
  </div>
</template>
