<script setup>
import { computed, defineProps, defineEmits } from "vue";
import { useStore } from "vuex";
import SearchBox from "@/components/layout/RE/policies/searchbox.vue";
import SingleEditableDetail from "@/components/layout/generalUser/singleEditableDetail.vue";
import RotaCard from "@/components/ui/baseComponents/rotaCardWithData.vue";
import accordion from "@/components/ui/baseComponents/accordion.vue";
// import editInterviewPolicy from "@/pages/RE/newHirePolicies/editInterviewPolicy.vue";

const emits = defineEmits(["update:checklists", "update:user"]);
const props = defineProps({
  moduleConfig: {
    type: Object,
    required: true,
  },
  checklists: {
    type: Array,
    required: false,
    defualt: () => [],
  },
  user: {
    type: [Object, null],
    required: true,
  },
  mainTitle: {
    type: String,
    default: "",
  },
});

const checkListSync = computed({
  get() {
    return props.checklists;
  },
  set(value) {
    emits("update:checklists", value);
  },
});
const currentUserSync = computed({
  get() {
    return props.user;
  },
  set(value) {
    emits("update:user", value);
  },
});
const store = useStore();

const userSelected = async (user) => {
  currentUserSync.value = user;
  await store.dispatch(
    `${props.moduleConfig.module}/${props.moduleConfig.action}`,
    {
      practice: store.getters["MAgeneralModule/getCurrentPractice"],
      role: user.roles[0]?.id,
      fetchWithRole: props.moduleConfig.fetchWithRole,
    }
  );
};
const getRoles = computed(() => {
  return store.getters["admin/getRoles"];
});

const inductionPolicies = computed(() => {
  let data =
    store.getters[`${props.moduleConfig.module}/${props.moduleConfig.getter}`];
  if (props.moduleConfig.appendData) {
    data = data.data;
  }
  return data;
});
const groupBy = (arr, key) => {
  const initialValue = {};
  return arr?.reduce((acc, cval) => {
    const myAttribute = cval[key];
    acc[myAttribute] = [...(acc[myAttribute] || []), cval];
    return acc;
  }, initialValue);
};
let groupedQuestion = computed(() => {
  return groupBy(inductionPolicies.value.questions, "head");
});
const headsArray = [
  "Pre-Appraisal",
  "Appraisal",
  "Questions from the Candidate",
];
const userData = computed(() => {
  return [
    {
      title: "Position Information",
      fields: [
        {
          title: "Job Title:",
          value: currentUserSync?.value?.position_summary.job_title,
        },
        {
          title: "Contract Type:",
          value: currentUserSync?.value?.position_summary.contract_type,
        },
        {
          title: "Department:",
          value: currentUserSync?.value?.position_summary.department,
        },
        {
          title: "Reports to:",
          value: currentUserSync?.value?.position_summary.reports_to,
        },
        {
          title: "Probation end date:",
          value: currentUserSync?.value?.position_summary.probation_end_date,
        },
        {
          title: "Notice Period:",
          value: currentUserSync?.value?.position_summary.notice_period,
        },
      ],
    },
  ];
});
</script>
<template>
  <div class="px-5 w-full">
    <SearchBox
      :secondaryOptions="getRoles"
      :layout="'horizontal'"
      class="w-full shadow-md px-5"
      :title="props.mainTitle"
      @user-selected="userSelected"
    />
  </div>
  <div class="w-full pt-5 px-5 flex flex-col flex-grow justify-between">
    <template v-if="currentUserSync">
      <div
        class="bg-gray-200 rounded-md p-4 flex flex-col shadow-lg"
        v-for="item in userData"
        :key="item.index"
      >
        <p class="text-lg font-medium mb-5">{{ item.title }}</p>
        <div class="flex flex-grow">
          <div class="flex flex-col w-full">
            <div
              class="flex flex-grow justify-between items-center"
              v-for="info in item.fields"
              :key="info.index"
            >
              <SingleEditableDetail :info="info" :editKey="false" />
            </div>
          </div>
        </div>
      </div>
      <template v-if="currentUserSync?.work_patterns">
        <RotaCard
          :showSelect="false"
          :bgColour="'bg-gray-200 px-3 mt-5'"
          :showTitle="true"
          :data="currentUserSync.work_patterns[0]?.work_timings"
          :title="'Rota Information'"
          :titleClass="'font-medium'"
        ></RotaCard>
      </template>
      <template v-if="currentUserSync?.work_patterns.length < 1">
        <div class="p-3 text-center bg-gray-200 my-3 rounded-md">
          No Rota Information Found
        </div>
      </template>
      <div class="bg-gray-200 rounded-md p-4 flex flex-col shadow-lg mt-1">
        <p class="text-lg font-medium mb-5">Select Policies</p>
        <div class="flex flex-grow">
          <div class="flex flex-col w-full">
            <template v-if="inductionPolicies">
              <template v-if="inductionPolicies?.length > 0">
                <div
                  class="flex flex-grow justify-start items-center"
                  v-for="(info, index) in inductionPolicies"
                  :key="index"
                >
                  <input
                    :checked="!props.checklists"
                    :disabled="!props.checklists"
                    v-model="checkListSync"
                    type="checkbox"
                    :value="info.id"
                    name="inductionID"
                    class="mr-2"
                    :id="info.id"
                  />
                  <label :for="info.id"> {{ info.name }}</label>
                </div>
              </template>
              <template v-else>
                <div class="flex flex-grow justify-start items-center">
                  <div class="w-1/2">
                    <input
                      :checked="!props.checklists"
                      :disabled="!props.checklists"
                      v-model="checkListSync"
                      type="checkbox"
                      :value="inductionPolicies.id"
                      name="inductionID"
                      class="mr-2"
                      :id="inductionPolicies.id"
                    />
                    <label :for="inductionPolicies.id">
                      {{ inductionPolicies.name }}</label
                    >
                  </div>
                  <div class="w-1/2">
                    <div
                      class="flex flex-col gap-y-2 my-5"
                      v-if="groupedQuestion"
                    >
                      <template
                        v-for="item in Object.keys(groupedQuestion)"
                        :key="item.index"
                      >
                        <accordion
                          :questionsData="groupedQuestion[item]"
                          :title="headsArray[item - 1]"
                        >
                          <div
                            class="flex justify-between gap-y-3"
                            v-for="question in groupedQuestion[item]"
                            :key="question.id"
                          >
                            <p>{{ question.question }}</p>
                            <p>{{ question.type }}</p>
                          </div>
                        </accordion>
                      </template>
                    </div>
                  </div>
                </div>
              </template>
            </template>
            <p v-else class="text-center">No Policy Found</p>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>