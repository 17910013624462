<template>
  <div class="rounded-xl p-2" :class="[bgColour ? bgColour : 'bg-lightGrey']">
    <h3 class="text-lg text-grey pt-2" :class="titleClass" v-if="showTitle">
      {{ title }}
    </h3>
    <!-- <div class="flex px-2 w-full my-2 items-center" v-if="showSelect">
      <label for="rotaSelect" class="w-3/12">Rota Name:</label>
      <select
        name="rotaSelect"
        class="rounded-lg border bg-white w-full md:w-11/12 h-10 pl-2"
        :class="border ? border : 'border-darkGrey'"
        v-model="working_time_pattern"
        @change="updatePattern($event)"
      >
        <option value="" selected disabled>Please Select...</option>
        <option v-for="pattern in data" :key="pattern.id" :value="pattern.id">
          {{ pattern.name }}
        </option>
      </select>
    </div> -->
    <div
      class="flex flex-col items-center xl:flex-row my-1 md:mr-10"
      v-for="(pattern, index) in data"
      :key="index"
    >
      <!-- Start / End / Break Time -->
      <div class="w-full lg:w-1/2">
        <div class="flex">
          <div class="lg:flex-1">
            <label class="font-semibold text-grey pb-1" for="start_time"
              >Start Time</label
            >
            <input
              disabled
              placeholder="Start"
              type="time"
              name="start_time"
              v-model="pattern.start_time"
              class="
                rounded-lg
                border border-darkGrey
                bg-lightGrey
                w-full
                h-10
                pl-2
              "
            />
          </div>
          <div class="lg:flex-1 ml-3">
            <label class="font-semibold text-grey pb-1" for="end_time"
              >End Time</label
            >
            <input
              disabled
              placeholder="End"
              type="time"
              name="end_time"
              v-model="pattern.end_time"
              class="
                rounded-lg
                border border-darkGrey
                bg-lightGrey
                w-full
                h-10
                pl-2
              "
            />
          </div>
          <div class="lg:flex-1 ml-3">
            <label class="font-semibold text-grey pb-1" for="break_time"
              >Break</label
            >
            <input
              disabled
              placeholder="Break"
              type="number"
              name="break_time"
              v-model="pattern.break_time"
              class="
                rounded-lg
                border border-darkGrey
                bg-lightGrey
                w-full
                h-10
                pl-2
              "
            />
          </div>
        </div>
      </div>
      <!-- Days Checklist -->
      <div class="w-full lg:w-1/2 pb-2 pl-2">
        <label
          class="font-semibold text-grey flex pb-1"
          for="working_time_pattern"
          >Repeat</label
        >
        <div class="w-full flex justify-between">
          <!-- Monday -->
          <article class="relative w-9 h-9 m-1 border-2 border-grey box-border">
            <input
              disabled
              type="checkbox"
              name="Monday"
              value="monday"
              v-model="pattern.repeat_days"
              class="absolute top-0 left-0 w-9 h-9 opacity-0"
            />
            <div
              class="
                w-full
                h-full
                flex
                justify-center
                items-center
                transition
                duration-500
              "
            >
              M
            </div>
          </article>
          <!-- Tuesday -->
          <article class="relative w-9 h-9 m-1 border-2 border-grey box-border">
            <input
              disabled
              type="checkbox"
              name="Tuesday"
              value="tuesday"
              v-model="pattern.repeat_days"
              class="absolute top-0 left-0 w-9 h-9 opacity-0"
            />
            <div
              class="
                w-full
                h-full
                flex
                justify-center
                items-center
                transition
                duration-500
              "
            >
              T
            </div>
          </article>
          <!-- Wednesday -->
          <article
            class="
              relative
              w-9
              h-9
              m-1
              float-left
              border-2 border-grey
              box-border
            "
          >
            <input
              disabled
              type="checkbox"
              name="Wednesday"
              value="wednesday"
              v-model="pattern.repeat_days"
              class="absolute top-0 left-0 w-9 h-9 opacity-0"
            />
            <div
              class="
                w-full
                h-full
                flex
                justify-center
                items-center
                transition
                duration-500
              "
            >
              W
            </div>
          </article>
          <!-- Thursday -->
          <article
            class="
              relative
              w-9
              h-9
              m-1
              float-left
              border-2 border-grey
              box-border
            "
          >
            <input
              disabled
              type="checkbox"
              name="Thursday"
              value="thursday"
              v-model="pattern.repeat_days"
              class="absolute top-0 left-0 w-9 h-9 opacity-0"
            />
            <div
              class="
                w-full
                h-full
                flex
                justify-center
                items-center
                transition
                duration-500
              "
            >
              T
            </div>
          </article>
          <!-- Friday -->
          <article
            class="
              relative
              w-9
              h-9
              m-1
              float-left
              border-2 border-grey
              box-border
            "
          >
            <input
              disabled
              type="checkbox"
              name="Friday"
              value="friday"
              v-model="pattern.repeat_days"
              class="absolute top-0 left-0 w-9 h-9 opacity-0"
            />
            <div
              class="
                w-full
                h-full
                flex
                justify-center
                items-center
                transition
                duration-500
              "
            >
              F
            </div>
          </article>
          <!-- Saturday -->
          <article
            class="
              relative
              w-9
              h-9
              m-1
              float-left
              border-2 border-grey
              box-border
            "
          >
            <input
              disabled
              type="checkbox"
              name="Saturday"
              value="saturday"
              v-model="pattern.repeat_days"
              class="absolute top-0 left-0 w-9 h-9 opacity-0"
            />
            <div
              class="
                w-full
                h-full
                flex
                justify-center
                items-center
                transition
                duration-500
              "
            >
              S
            </div>
          </article>
          <!-- Sunday -->
          <article
            class="
              relative
              w-9
              h-9
              m-1
              float-left
              border-2 border-grey
              box-border
            "
          >
            <input
              disabled
              type="checkbox"
              name="Synday"
              value="sunday"
              v-model="pattern.repeat_days"
              class="absolute top-0 left-0 w-9 h-9 opacity-0"
            />
            <div
              class="
                w-full
                h-full
                flex
                justify-center
                items-center
                transition
                duration-500
              "
            >
              S
            </div>
          </article>
          <!-- <div class="flex justify-end items-center">
                  <span
                    class="material-icons cursor-pointer text-grey"
                    @click.prevent="deleteOption(index)"
                  >
                    delete
                  </span>
                </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import moment from "moment";
export default {
  emits: ["selectedPattern"],
  props: {
    bgColour: {
      type: String,
    },
    border: {
      type: String,
    },
    showSelect: {
      type: Boolean,
      required: true,
    },
    showTitle: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Array,
    },
    title: {
      type: String,
      default: "Rota Information",
    },
    titleClass: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      rotaCardIsLoading: false,
      reasonForRecruitment: null,
      working_time_pattern: "",
      workPattern: null,
    };
  },
  // created() {
  // this.fetchWorkPatterns();
  // },
  // methods: {
  //   async fetchWorkPatterns() {
  //     try {
  //       this.workPattermIsLoading = true;
  //       await this.$store.dispatch("profile/fetchWorkPatterns");
  //       this.workPattermIsLoading = false;
  //     } catch (error) {
  //       this.error = error.message || "Something went wrong!";
  //     }
  //   },
  //   updatePattern(event) {
  //     this.fetchWorkTimings(event.target.value);
  //     this.$emit("selectedPattern", this.working_time_pattern);
  //   },
  //   async fetchWorkTimings(payload) {
  //     try {
  //       const actionPayload = {
  //         work_pattern: payload,
  //       };
  //       // this.isWorkingTimmingsLoading = true;
  //       await this.$store.dispatch("profile/loadWorkTimings", actionPayload);
  //       // this.isWorkingTimmingsLoading = false;
  //     } catch (error) {
  //       this.error = error.message || "Something went wrong!";
  //     }
  //   },
  // },
  // computed: {
  //   convertedTime() {
  //     return moment;
  //   },
  //   getWorkPatterns() {
  //     return this.$store.getters["profile/getWorkPatterns"];
  //   },
  //   getWorkTimings() {
  //     return this.$store.getters["profile/getWorkTimings"];
  //   },
  // },
};
</script>

<style scoped>
input[type="checkbox"]:checked ~ div {
  background-color: #ed8b00;
  color: white;
}
</style>
