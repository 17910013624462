<script setup>
import { onMounted, ref, reactive, defineEmits, defineProps } from "vue";
import { useStore } from "vuex";
import AdditionalStaffMemberSearch from "@/components/layout/RE/New_Interview/Right_Side/AdditionalStaffMemberSearch.vue";
import HQRequired from "@/components/layout/RE/New_Interview/Right_Side/HQRequired.vue";
import ScheduleInterview from "@/components/layout/RE/New_Interview/Right_Side/ScheduleInterview.vue";

const props = defineProps({
  title: {
    type: String,
    default: "",
  },
  secondaryTitle: {
    type: String,
    default: "",
  },
});

const emits = defineEmits(["submitForm"]);

const store = useStore();

const loading = ref(false);
const formData = reactive({});
const additionalStaffMember = ref(false);
const hqNeeded = ref(false);

onMounted(async () => {
  loading.value = true;
  await store.dispatch("admin/loadRoles", 1);
  loading.value = false;
});

const submitForm = async (data) => {
  try {
    const object = {
      date: data.date,
      time: data.time,
      duration: data.duration,
      is_hq_required: hqNeeded.value ? 1 : 0,
      is_additional_staff_required: additionalStaffMember.value ? 1 : 0,
      ...(hqNeeded.value && { hq_staff_id: formData.selectedHQUser }),
      ...(additionalStaffMember.value && {
        additional_staff_role_id: formData.selectedAdditionalUser,
      }),
    };
    emits("submitForm", object);
  } catch (error) {
    console.log(error);
  }
};

const setAdditionalStaffMember = (data) => {
  formData.selectedAdditionalUser = data;
};
const setHqRequired = (data) => {
  formData.selectedHQUser = data;
};
</script>
<template>
  <transition appear>
    <AdditionalStaffMemberSearch
      :title="`Is additional staff member required for this ${props.secondaryTitle}?`"
      @toggle-status="(status) => (additionalStaffMember = status === 'yes')"
      @set-additional-staff-member="setAdditionalStaffMember"
    />
  </transition>

  <slot name="extrafeatures"></slot>
  <transition appear>
    <HQRequired
      :title="`Is HQ required for this ${props.secondaryTitle}?`"
      @toggle-status="(status) => (hqNeeded = status === 'yes')"
      @set-hq-required="setHqRequired"
    />
  </transition>
  <ScheduleInterview @submit-form="submitForm" :title="props.title" />
</template>