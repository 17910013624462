export default {
  data() {
    return {
      payload: [],
      filterSelection: null,
      inputSearchValue: "",
      responseData: null,
      loadingUsers: false,
      selectedUser: null,
    };
  },

  methods: {
    async submitSearch() {
      this.v$.$validate();
      if (!this.v$.$error) {
        console.log(this.selectedUser);
        this.loadingUsers = true;
        this.payload = [];
        this.payload.push(this.filterSelection.value, this.inputSearchValue);
        console.log(this.payload);
        const requestPaylod = {
          filter: this.payload[0],
          value: this.payload[1].toString(),
        };
        const response = await this.$store.dispatch(
          "REgeneralModule/loadSingleUser",
          requestPaylod
        );
        this.responseData = response.data.users.data;
        this.loadingUsers = false;
      }
    },
  },
};
