<script setup>
import { defineProps, toRefs, ref } from "vue";
const props = defineProps({
  info: {
    type: Object,
    required: true,
  },
  editKey: {
    type: Boolean,
    default: true,
  },
});
const { info } = toRefs(props);
const editMode = ref(false);
</script>
<template>
  <div class="w-1/3">
    <p class="text-md font-medium">
      {{ info.title }}
    </p>
  </div>
  <div
    class="flex flex-grow"
    :class="[
      { 'justify-between': props.editKey },
      { 'justify-end': !props.editKey },
    ]"
  >
    <component
      :is="editMode ? 'input' : 'span'"
      :class="[{ border: editMode }]"
    >
      {{ info.value || "N/A" }}</component
    >
    <div
      v-if="props.editKey"
      class="cursor-pointer text-teal"
      @click="editMode = !editMode"
    >
      {{ editMode ? "save" : "edit" }}
    </div>
  </div>
</template>